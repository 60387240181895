import styled from 'styled-components';
import StyledSection from '../StyledSection';

const StyledInStreamSection = styled(StyledSection)`
  p:first-of-type {
    margin-top: 0;
  }

  table {
    margin: 1.5rem 0;
  }
`;

export default StyledInStreamSection;
