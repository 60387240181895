import React, { Fragment } from 'react';
import LayoutWrapper from '../components/LayoutWrapper';
import Hero from '../components/Hero';
import Intro from '../components/sections/Intro';
import LeadTimes from '../components/sections/LeadTimes';
import GuideLines from '../components/sections/Guidelines';
import DesktopTakeovers from '../components/sections/DesktopTakeovers';
import RunOfNetwork from '../components/sections/RunOfNetwork';
import HTML5 from '../components/sections/HTML5';
import MobileTakeovers from '../components/sections/MobileTakeovers';
import Programatic from '../components/sections/Programatic';
import Instream from '../components/sections/InStream';
import Compliance from '../components/sections/Compliance';
import Contact from '../components/sections/Contact';

const IndexPage = () => {
  return (
    <Fragment>
      <Hero />
      <LayoutWrapper>
        <Intro />
        <LeadTimes />
        <GuideLines />
        <HTML5 />
        <DesktopTakeovers />
        <MobileTakeovers />
        <RunOfNetwork />
        <Instream />
        <Programatic />
        <Compliance />
        <Contact />
      </LayoutWrapper>
    </Fragment>
  );
};

export default IndexPage;
