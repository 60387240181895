/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import StyledMobileTakeoverSection from './style';
import downloadIcon from '../../../assets/download_icon.png';

export default () => (
  <StaticQuery
    query={graphql`
      {
        allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/mobiletakeovers/" } }
          sort: { fields: [frontmatter___order], order: ASC }
        ) {
          edges {
            node {
              id
              html
              frontmatter {
                unit
                template
                preview
              }
            }
          }
        }
      }
    `}
    render={data => (
      <StyledMobileTakeoverSection>
        <a className="anchor-point" id="mobile-takeovers" />
        <h2>Mobile Takeovers</h2>
        <p>
          On mobile we offer a takeover made up of two units: the Fishbowl and
          Sticky Leaderboard.
        </p>
        <p>
          The Fishbowl is a large format that is placed in the first ad unit on
          a page. We have PSD templates below with two options:
        </p>
        <ul>
          <li>Static Fishbowls (made up of a single jpg image)</li>
          <li>Video Fishbowls (these can include a small autoplaying video)</li>
        </ul>
        <p>
          The sticky leaderboard is a 320x100 banner which sticks to the bottom
          of the viewport with a close button.
        </p>
        <p>
          For static and video Fishbowls please use the PSD templates provided.
          They will indicate which areas are the ‘safe’ zones for key messaging
          and logos. Please also stick to the file sizes below.
        </p>
        <div className="important-note">
          <p>
            <strong>IMPORTANT NOTE ON FILE SIZES</strong>
          </p>
          <p>
            Below we specify file sizes in order to ensure your creatives load
            fast when delivered on our sites. Google Ad Manager supports files
            up to 1MB in size but please try to make images and video as small
            as possible.
          </p>
          <p>
            Smaller creative sizes also mean less bandwidth, less computer
            power, less battery drain and are better for the environment - a 1MB
            video doing 2M impressions in the UK would release over 1 Tonne of
            Carbon (source:{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://good-loop.com/resources/carbon-calculator"
            >
              https://good-loop.com/resources/carbon-calculator
            </a>
            ).
          </p>
          <p>
            So keeping files small helps make a difference, and faster ads are a
            win-win for everyone.
          </p>
        </div>
        {data.allMarkdownRemark.edges.map((mobileTakeover, index, arr) => (
          <div key={mobileTakeover.node.id} className="mobile-takeover-wrapper">
            <h3>{mobileTakeover.node.frontmatter.unit}</h3>
            <div
              className="table-wrapper"
              dangerouslySetInnerHTML={{ __html: mobileTakeover.node.html }}
            />
            {mobileTakeover.node.frontmatter.template && (
              <button
                onClick={() =>
                  window.open(
                    mobileTakeover.node.frontmatter.template,
                    '_blank'
                  )
                }
                className="download-btn"
              >
                <img src={downloadIcon} alt="download" />
                Download PSD Template
              </button>
            )}
            {index !== arr.length - 1 && <hr />}
          </div>
        ))}
      </StyledMobileTakeoverSection>
    )}
  />
);
