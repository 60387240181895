import styled from 'styled-components';
import StyledSection from '../StyledSection';

const StyledRONSection = styled(StyledSection)`
  p:first-of-type {
    margin-top: 0;
  }

  .ron-table {
    margin-bottom: 3rem;
    margin-top: 1.5rem;
  }
`;

export default StyledRONSection;
