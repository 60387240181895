import styled from 'styled-components';
import StyledSection from '../StyledSection';

const StyledComplianceSection = styled(StyledSection)`
  p:first-of-type {
    margin-top: 0;
  }
`;

export default StyledComplianceSection;
